<template>
  <div>
    <v-card class="mb-4">
      <v-toolbar flat dark dense color="accent">
        <v-toolbar-title>Pool Party Bookings</v-toolbar-title>
        <div class="spacer"></div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              class="mr-2"
              color="blue lighten-4 blue--text"
              :href="
                'mailto:' +
                order.customer.email +
                '?subject=Your Pool Party Booking with Arnprior&body=You have the Pool Party booked for:%0D%0A' +
                mailText
              "
            >
              <v-icon small>mdi-email</v-icon>
            </v-btn>
          </template>
          <span>Send Email</span>
        </v-tooltip>

        <!--<v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="green lighten-4 green--text"
              v-on="on"
              @click="openForm()"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Pool Party Booking</span> </v-tooltip
        >-->
      </v-toolbar>
      <v-divider></v-divider>
      <v-simple-table v-if="bookings.length > 0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Total Swimmers</th>
            <th>Total Pool Side</th>
            <th>Description</th>
            <th class="text-right">Cost</th>
            <th class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="booking in bookings" :key="booking.id">
            <td v-html="booking.formatted_dates.start_date" />
            <td
              v-html="
                booking.formatted_dates.start_time +
                ' - ' +
                booking.formatted_dates.end_time
              "
            ></td>
            <td>{{ booking.booking_is.total_swimmers }}</td>
            <td>{{ booking.booking_is.total_poolside }}</td>
            <td v-html="booking.booking_is.description" />
            <td
              class="text-right"
              v-html="'£' + booking.booking_is.total_cost"
            />
            <td class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    class="mr-2"
                    v-on="on"
                    @click="openForm(booking)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Individual Booking</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="openDelete(booking)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Remove Pool Party Booking</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-card-text v-else>
        There are no Pool Party Bookings in this Order.
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="poolPartyBookingDialog.dialog"
      scrollable
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ poolPartyBookingDialog.isEditing ? "Edit" : "Create a" }} Pool
          Party Booking
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form method="post" id="pool-booking-form">
            <pool-party
              v-on:close-dialog="closeDialog"
              v-if="poolPartyBookingDialog.isEditing"
              ref="pool"
              :order="order"
              :booking="poolPartyBookingDialog.booking"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteBooking.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Remove Pool Party Booking</v-card-title>
        <v-card-text
          >Are you sure you want to remove this Pool Party Booking? If no other
          items are attached to this order, the entire order will be removed as
          well.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteBooking.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PoolParty from "@/modules/arnprior/views/components/forms/PoolParty";

export default {
  props: {
    order: Object,
    bookings: Array,
  },
  components: {
    PoolParty,
  },

  data() {
    return {
      poolPartyBookingDialog: {
        dialog: false,
        booking: {},
        loading: false,
        isEditing: false,
      },
      deleteBooking: {
        dialog: false,
        booking: {},
        loading: false,
      },
    };
  },

  computed: {
    mailText() {
      var total = "";

      this.bookings.forEach((value) => {
        total +=
          value.formatted_dates.start_date +
          " on " +
          value.formatted_dates.start_time +
          " - " +
          value.formatted_dates.end_time +
          " %0D%0A";
      });

      total +=
        "YOUR ACCESS CODE FOR THE POOL BUILDING IS 8683.%0D%0APLEASE ENTER THIS CODE INTO THE LOCK BOX LOCATED ON THE POST%0D%0APLEASE RETURN THE KEY TO THE LOCK BOX BEFORE YOU LEAVE.%0D%0APLEASE DO NOT LOCK YOURSELF IN THE BUILDING%0D%0AAny Problems, please call or text me on 07902180564Inflatables%0D%0AWe do not supply inflatables, but do have some foam floats and noodles. You are welcome to bring your own to Arnrpior Pool. %0D%0AThe hour slot includes%0D%0AArriving%0D%0AChanging%0D%0ASwimming%0D%0AChanging%0D%0ALeaving%0D%0AFor full pool T&Cs Please see our website %0D%0ASafety%0D%0ANo lifeguard is on site%0D%0ANo lone swimmers%0D%0AThere must always be a responsible/competent adult swimmer present when children are present. %0D%0AChildren under 8 must be accompanied by an adult in the water%0D%0AUsers have a duty of care for their guests..%0D%0AMaximum of 10 in the pool at any one time. Extra adults can be poolside%0D%0ANo Diving into pool. (Max depth of the pool 1.2m)%0D%0AAdult to child ratio%0D%0ABaby 1 to 1%0D%0AChildren under 4yrs: 1 adult to 2 children.%0D%0AChildren aged 4-7yrs: 1 adult to 4 children.%0D%0A";

      return total;
    },
  },

  methods: {
    closeDialog: function () {
      this.poolPartyBookingDialog.dialog = false;
    },

    openForm(booking = null) {
      if (booking !== null) {
        this.poolPartyBookingDialog.booking = booking;
        this.poolPartyBookingDialog.isEditing = true;
      }
      this.poolPartyBookingDialog.dialog = true;
    },

    resetForm() {
      this.poolPartyBookingDialog.dialog = false;
      this.poolPartyBookingDialog.booking = {};
      this.poolPartyBookingDialog.isEditing = false;
      // this.$refs.pool.resetForm();
    },

    openDelete(booking) {
      this.deleteBooking.booking = booking;
      this.deleteBooking.dialog = true;
    },

    resetDelete() {
      this.deleteBooking.dialog = false;
      this.deleteBooking.booking = {};
      this.deleteBooking.loading = false;
    },

    saveDelete() {
      this.deleteBooking.loading = true;

      this.$store
        .dispatch("arnprior/ordersStore/deleteBooking", {
          appId: this.$route.params.id,
          bookingId: this.deleteBooking.booking.id,
          orderId: this.$route.params.orderId,
        })
        .then((resp) => {
          if (resp.data.orderDeleted) {
            this.$store.commit("showGlobalSnackbar", {
              open: true,
              text: "Order has been Removed",
              color: "orange",
            });

            this.$router.push({
              name: "module-arnprior-bookings",
              params: {
                appId: this.$route.params.id,
              },
            });
          }

          this.resetDelete();
        })
        .catch(() => {
          this.deleteBooking.loading = false;
        });
    },
  },
};
</script>
