<template>
  <div>
    <v-menu
      ref="menu"
      v-model="datePicker.menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          label="Choose Date"
          v-model="dateText"
          prepend-inner-icon="mdi-calendar"
          outlined
          class="mt-1"
          readonly
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-overlay :value="datePicker.overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-date-picker
        v-model="date"
        first-day-of-week="1"
        :allowed-dates="allowedDates"
        :min="datePicker.min"
        @change="dateSelected"
        @update:picker-date="monthChanged"
      />
    </v-menu>
    <div v-if="timeSlots.length > 0 || loaders.timeSlots" class="mb-6">
      <span class="subheading">Choose Time</span>
      <v-progress-linear indeterminate class="mt-2" v-if="loaders.timeSlots" />
      <v-chip-group
        v-model="fields.timeSlot"
        active-class="blue--text"
        column
        v-else
      >
        <v-chip
          v-for="(slot, index) in timeSlots"
          :key="`${index}-time-slot`"
          :value="slot.datetime"
          >{{ slot.start_time }}</v-chip
        >
      </v-chip-group>
    </div>

    <div>
      <v-btn
        color="primary"
        @click="createBooking()"
        :loading="loaders.complete"
        >Save</v-btn
      >
      <v-btn text @click="stepPosition = 3">Back</v-btn>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    order: {
      type: Object,
      default: null,
    },
    booking: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      stepPosition: 1,
      datePicker: {
        menu: false,
        min: new Date().toISOString().slice(0, 10),
        loading: true,
        disabledDays: [],
        overlay: false,
      },
      showDialog: true,
      fields: {
        timeSlot: null,
        customerId: null,
      },
      date: null,
      timeSlots: [],
      loaders: {
        timeSlots: false,
        summary: false,
        complete: false,
      },
      totals: {
        subTotal: "0.00",
        vatTotal: "0.00",
        total: "0.00",
      },
      isEditing: false,
    };
  },

  mounted() {
    if (this.order !== null) {
      this.fields.customerId = this.order.customer_id;
      this.fields.orderId = this.order.id;
      if (this.booking) {
        this.isEditing = true;
        this.date = this.booking.start_date.substr(0, 10);
      }
    }

    Promise.all([
      this.$store.dispatch("arnprior/customersStore/loadCustomers", {
        appId: this.$route.params.id,
      }),
    ]);
  },

  watch: {
    date: function (value) {
      if (value != null) {
        this.dateSelected(value);
      }
    },
  },

  computed: {
    dateText() {
      return this.formatDate(this.date);
    },
    customers() {
      return this.$store.getters["arnprior/customersStore/all"];
    },
  },

  methods: {
    resetForm() {
      this.date = null;
      this.stepPosition = 1;
      this.isEditing = false;
      this.fields.timeSlot = null;
      this.dialog = false;
      this.loading = false;
      this.errors = {};
      this.loaders.complete = false;
    },

    allowedDates(date) {
      return !this.datePicker.disabledDays.includes(date);
    },
    monthChanged(date) {
      this.datePicker.overlay = true;

      this.$store
        .dispatch("arnprior/poolStore/getDisabledDays", {
          appId: this.$route.params.id,
          fields: {
            date: date.concat("-01"),
          },
        })
        .then((resp) => {
          this.datePicker.disabledDays = resp.disabledDays;
          this.datePicker.overlay = false;
        })
        .catch(() => {
          this.datePicker.overlay = false;
        });
    },
    dateSelected(date) {
      this.loaders.timeSlots = true;
      this.datePicker.menu = false;

      this.$store
        .dispatch("arnprior/poolStore/getSwimSlots", {
          appId: this.$route.params.id,
          fields: {
            date,
          },
        })
        .then((resp) => {
          this.timeSlots = resp.swim_slots;
        })
        .finally(() => (this.loaders.timeSlots = false));
    },

    createBooking() {
      this.loaders.complete = true;

      this.errors = {};

      const fields = _.mapKeys(this.fields, (field, key) => {
        return _.snakeCase(key);
      });

      let payload = {
        appId: this.$route.params.id,
        fields,
      };

      payload.isEditing = true;

      payload.bookingId = this.booking.id;

      this.$store
        .dispatch("arnprior/ordersStore/saveSwimmBooking", payload)
        .then(() => {
          this.$emit("close-dialog");
          this.resetForm();
        })
        .catch(() => {
          //  this.errors = err.response.data.errors;
          this.loaders.complete = false;
        });
    },
  },
};
</script>
