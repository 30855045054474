<template>
  <div>
    <v-card class="mb-4">
      <v-toolbar flat dark dense color="accent">
        <v-toolbar-title>Party Pod Bookings</v-toolbar-title>
        <div class="spacer"></div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              class="mr-2"
              color="blue lighten-4 blue--text"
              :href="
                'mailto:' +
                order.customer.email +
                '?subject=Your Party Pod Booking with Arnprior&body=You have the Party Pod booked for:%0D%0A' +
                mailText
              "
            >
              <v-icon small>mdi-email</v-icon>
            </v-btn>
          </template>
          <span>Send Email</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
      <v-simple-table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Description</th>
            <th class="text-right">Cost</th>
            <th class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="booking in bookings" :key="`${booking.id}-party-pod-row`">
            <td v-text="booking.formatted_dates.start_date" />
            <td
              v-text="
                booking.formatted_dates.start_time +
                ' - ' +
                booking.formatted_dates.end_time
              "
            />
            <td v-text="booking.booking_is.description" />
            <td
              class="text-right"
              v-html="'£' + booking.booking_is.total_cost"
            />
            <td class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    class="mr-2"
                    v-on="on"
                    @click="openForm(booking)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Individual Booking</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="openDelete(booking)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Remove Party Pod Booking</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <v-dialog v-model="partyPodBookingDialog.dialog" scrollable max-width="600">
      <v-card>
        <v-card-title class="headline"
          >{{ partyPodBookingDialog.isEditing ? "Edit" : "Create a" }}
          Party Pod Booking
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form method="post" id="party-pod-booking-form">
            <party-pod
              v-on:close-dialog="closeDialog"
              v-if="partyPodBookingDialog.isEditing"
              ref="pod"
              :order="order"
              :booking="partyPodBookingDialog.booking"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteBooking.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Remove Party Pod Booking</v-card-title>
        <v-card-text
          >Are you sure you want to remove this Party Pod Booking? If no other
          items are attached to this order, the entire order will be removed as
          well.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteBooking.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PartyPod from "@/modules/arnprior/views/components/forms/PartyPod";

export default {
  props: {
    bookings: Array,
    order: Object,
  },
  components: {
    PartyPod,
  },

  data() {
    return {
      deleteBooking: {
        dialog: false,
        booking: {},
        loading: false,
      },
      partyPodBookingDialog: {
        dialog: false,
        booking: {},
        loading: false,
        isEditing: false,
      },
    };
  },

  computed: {
    mailText() {
      var total = "";

      this.bookings.forEach((value) => {
        total +=
          value.formatted_dates.start_date +
          " on " +
          value.formatted_dates.start_time +
          " - " +
          value.formatted_dates.end_time +
          " %0D%0A";
      });

      total +=
        "We look forward to welcoming you to our party pod. %0D%0A%0D%0ARemember your access code 9476 %0D%0A%0D%0AWe ask you to leave the party pod nice and clean for the next group of party guests. %0D%0A%0D%0ANEW - We have a vending machine on site where you can purchase tea, coffee, hot chocolate and Fresh OJ, as well as firewood, and marshmallows for toasting on out firepit. %0D%0AToilet facilities are currently in the pool building. Please go accompanied by an adult, and respect the pool users. %0D%0AAny Problems, please call or text me on 07902180564 %0D%0ARebecca";

      return total;
    },
  },

  methods: {
    closeDialog: function () {
      this.partyPodBookingDialog.dialog = false;
    },

    openForm(booking = null) {
      if (booking !== null) {
        this.partyPodBookingDialog.booking = booking;
        this.partyPodBookingDialog.isEditing = true;
      }
      this.partyPodBookingDialog.dialog = true;
    },

    resetForm() {
      this.partyPodBookingDialog.dialog = false;
      this.partyPodBookingDialog.booking = {};
      this.partyPodBookingDialog.isEditing = false;
      //this.$refs.pod.resetForm();
    },

    openDelete(booking) {
      this.deleteBooking.booking = booking;
      this.deleteBooking.dialog = true;
    },

    resetDelete() {
      this.deleteBooking.dialog = false;
      this.deleteBooking.booking = {};
      this.deleteBooking.loading = false;
    },

    saveDelete() {
      this.deleteBooking.loading = true;

      this.$store
        .dispatch("arnprior/ordersStore/deleteBooking", {
          appId: this.$route.params.id,
          bookingId: this.deleteBooking.booking.id,
          orderId: this.$route.params.orderId,
        })
        .then((resp) => {
          if (resp.data.orderDeleted) {
            this.$store.commit("showGlobalSnackbar", {
              open: true,
              text: "Order has been Removed",
              color: "orange",
            });

            this.$router.push({
              name: "module-arnprior-bookings",
              params: {
                appId: this.$route.params.id,
              },
            });
          }

          this.resetDelete();
        })
        .catch(() => {
          this.deleteBooking.loading = false;
        });
    },
  },
};
</script>
