<template>
  <div>
    <v-card class="mb-4">
      <v-toolbar flat dark dense color="accent">
        <v-toolbar-title>Extras</v-toolbar-title>
        <div class="spacer"></div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="green lighten-4 green--text"
              v-on="on"
              @click="openForm()"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Extra</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
      <v-simple-table v-if="extras.length > 0">
        <thead>
          <tr>
            <th>Extra Name</th>
            <th>Quantity Requested</th>
            <th class="text-right">Total Cost</th>
            <th class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="extra in extras" :key="extra.id">
            <td v-html="extra.name" />
            <td v-html="extra.pivot.quantity" />
            <td class="text-right" v-html="'£' + extra.pivot.total_cost" />
            <td class="text-right">
              <!--<v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="openForm(extra)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Extra</span>
              </v-tooltip>-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="openDelete(extra)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Remove Extra</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-card-text v-else> There are no Extras in this Order. </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600"
      @click:outside="resetForm()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ isEditing ? "Edit" : "Add a new" }} Extra To Booking</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form @submit.prevent="saveForm" method="post" id="extra-form">
            <v-select
              label="Extra *"
              :items="extrasAvailable"
              item-value="id"
              item-text="name"
              v-model="fields.extra_id"
              outlined
              background-color="white"
              :disabled="isEditing"
              :error="errors.hasOwnProperty('extra_id')"
              :error-messages="errors['extra_id']"
            ></v-select>
            <v-text-field
              label="Cost *"
              v-model="fields.total_cost"
              type="number"
              outlined
              prefix="£"
              background-color="white"
              :error="errors.hasOwnProperty('total_cost')"
              :error-messages="errors['total_cost']"
            ></v-text-field>
            <v-text-field
              label="Quantity *"
              v-model="fields.quantity"
              type="number"
              outlined
              background-color="white"
              :error="errors.hasOwnProperty('quantity')"
              :error-messages="errors['quantity']"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="extra-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteExtra.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline"
          >Delete {{ deleteExtra.booking }}</v-card-title
        >
        <v-card-text> Are you sure you want to delete this extra?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteExtra.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    extras: Array,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      extra: {},
      fields: {
        extra_id: null,
        quantity: null,
        total_cost: null,
      },
      deleteExtra: {
        dialog: false,
        extra: {},
        loading: false,
      },
      errors: {},
    };
  },

  computed: {
    extrasAvailable() {
      return this.$store.getters["arnprior/bookingsStore/extras"];
    },
  },

  methods: {
    openDelete(extra) {
      this.deleteExtra.extra = extra;
      this.deleteExtra.dialog = true;
    },

    resetDelete() {
      this.deleteExtra.dialog = false;
      this.deleteExtra.extra = {};
      this.deleteExtra.loading = false;
    },

    saveDelete() {
      this.deleteExtra.loading = true;

      this.$store
        .dispatch("arnprior/ordersStore/deleteExtra", {
          appId: this.$route.params.id,
          orderId: this.$route.params.orderId,
          extraId: this.deleteExtra.extra.pivot.extra_id,
          bookingId: this.deleteExtra.extra.pivot.booking_id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteExtra.loading = false;
        });
    },

    openForm(extra = null) {
      if (extra !== null) {
        this.isEditing = true;
        this.extra = extra;

        this.fields.extra_id = extra.id;
        this.fields.quantity = extra.pivot.quantity;
        this.fields.total_cost = extra.pivot.total_cost / extra.pivot.quantity;
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.extra = {};
      this.errors = {};
      this.fields.extra_id = null;
      this.fields.quantity = null;
      this.fields.total_cost = null;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        orderId: this.$route.params.orderId,
        fields: this.fields,
      };

      if (this.isEditing == true) {
        //payload.fields.extraId = this.extra.id;
        //payload.fields.bookingId = this.extra.pivot.booking_id;
      }

      this.$store
        .dispatch("arnprior/ordersStore/saveExtra", payload)
        .then(() => this.resetForm())
        .catch((err) => (this.errors = err.response.data.errors));
    },
  },
};
</script>
