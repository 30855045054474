<template>
  <div>
    <v-stepper v-model="stepPosition" vertical>
      <v-stepper-step :complete="stepPosition > 1" step="1"
        >Date</v-stepper-step
      >
      <v-stepper-content step="1">
        <v-menu
          ref="menu"
          v-model="datePicker.menu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Choose Date"
              v-model="dateText"
              prepend-inner-icon="mdi-calendar"
              outlined
              class="mt-1"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-overlay :value="datePicker.overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-date-picker
            v-model="date"
            first-day-of-week="1"
            :allowed-dates="allowedDates"
            :min="datePicker.min"
            @change="dateSelected"
            @update:picker-date="monthChanged"
          />
        </v-menu>

        <div class="mb-6">
          <span class="subheading">Choose Time</span>
          <v-progress-linear
            indeterminate
            class="mt-2"
            v-if="loaders.timeSlots"
          />
          <v-chip-group
            v-model="fields.start_time"
            active-class="blue--text"
            column
            v-else
          >
            <v-chip
              v-for="(slot, index) in timeSlots.start_slots"
              :key="`${index}-time-slot`"
              :value="slot.time"
              :class="slot.time.slice(-2) == '00' ? '' : 'hide'"
              >{{ slot.time }}</v-chip
            >
          </v-chip-group>
        </div>

        <div class="mb-6">
          <span class="subheading">Choose Time</span>
          <v-progress-linear
            indeterminate
            class="mt-2"
            v-if="loaders.timeSlots"
          />
          <v-chip-group
            v-model="fields.end_time"
            active-class="blue--text"
            column
            v-else
          >
            <v-chip
              v-for="(slot, index) in timeSlots.end_slots"
              :key="`${index}-time-slot`"
              :value="slot.time"
              :class="slot.time.slice(-2) == '00' ? '' : 'hide'"
              >{{ slot.time }}
            </v-chip>
          </v-chip-group>
        </div>

        <div>
          <v-btn
            v-if="this.isEditing"
            color="primary"
            @click="loadSummaryStep()"
            :loading="loaders.summary"
            >Continue</v-btn
          >

          <v-btn v-else color="primary" @click="stepPosition = 2"
            >Continue</v-btn
          >
        </div>
      </v-stepper-content>

      <v-stepper-step
        :complete="stepPosition > 2"
        step="2"
        v-if="!this.isEditing"
        >Customer Details</v-stepper-step
      >
      <v-stepper-content step="2" v-if="!this.isEditing">
        <v-select
          label="Customer *"
          v-model="fields.customer_id"
          :items="customers"
          item-text="full_name"
          item-value="id"
          outlined
          :disabled="this.isEditing"
          background-color="white"
        >
          <template v-slot:prepend-item>
            <div class="pa-3">
              <v-text-field
                label="Search Customer"
                v-model="customerSearchTerm"
                outlined
                autofocus
                dense
                hide-details
              ></v-text-field>
            </div>
            <v-divider></v-divider>
          </template>
        </v-select>
        <div>
          <v-btn
            color="primary"
            @click="loadSummaryStep()"
            :loading="loaders.summary"
            >Continue</v-btn
          >
          <v-btn text @click="stepPosition = 1">Back</v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-step
        :complete="stepPosition > 3"
        step="3"
        v-if="!this.isEditing"
        >Summary</v-stepper-step
      >

      <v-stepper-step :complete="stepPosition > 3" step="2" v-else
        >Summary</v-stepper-step
      >

      <v-stepper-content step="3">
        <v-simple-table class="mb-4">
          <tbody>
            <tr v-if="!this.isEditing">
              <th>Sub Total</th>
              <td class="text-right">£{{ fields.subTotal }}</td>
            </tr>
            <tr v-if="!this.isEditing">
              <th>VAT Total</th>
              <td class="text-right">£{{ fields.vatTotal }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td class="text-right">
                <v-text-field
                  v-model="fields.total"
                  outlined
                  prefix="£"
                  min="0"
                  type="number"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <div>
          <v-btn
            color="primary"
            @click="createBooking()"
            :loading="loaders.complete"
            >Save</v-btn
          >

          <v-btn v-if="!this.isEditing" text @click="stepPosition = 2"
            >Back</v-btn
          >
        </div>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    order: {
      type: Object,
      default: null,
    },
    booking: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      stepPosition: 1,
      customerSearchTerm: "",
      datePicker: {
        menu: false,
        min: new Date().toISOString().slice(0, 10),
        loading: true,
        disabledDays: [],
        overlay: false,
      },
      isEditing: false,
      availablePods: [],
      timeSlots: [],
      overlay: false,
      loaders: {
        pod: false,
        timeSlots: false,
        summary: false,
        complete: false,
      },
      date: null,
      fields: {
        date: null,
        start_time: null,
        end_time: null,
        bookingId: null,
        customer_id: null,
        subTotal: "0.00",
        vatTotal: "0.00",
        total: "0.00",
        isEditing: false,
      },
    };
  },

  mounted() {
    if (this.order !== null) {
      this.fields.customer_id = this.order.customer_id;
      this.fields.orderId = this.order.id;
      if (this.booking) {
        this.isEditing = true;
        this.date = this.booking.start_date.substr(0, 10);
        this.fields.total = this.booking.booking_is.total_cost;
        this.fields.isEditing = true;

        this.fields.bookingId = this.booking.id;
      }
    }

    Promise.all([
      this.$store.dispatch("arnprior/customersStore/loadCustomers", {
        appId: this.$route.params.id,
      }),
    ]);
  },

  watch: {
    date: function (value) {
      if (value != null) {
        this.dateSelected(value);
      }
    },

    "fields.total": function (newVal) {
      this.fields.subTotal = (newVal / 1.2).toFixed(2);
      this.fields.vatTotal = (newVal - this.fields.subTotal).toFixed(2);
    },
  },

  computed: {
    dateText() {
      return this.formatDate(this.date);
    },

    customers() {
      let customers = this.$store.getters["arnprior/customersStore/all"];

      if (this.customerSearchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();
          const customerSearchTerm = this.customerSearchTerm.toLowerCase();
          return name.includes(customerSearchTerm);
        });
      }

      return customers;
    },
  },
  methods: {
    resetForm() {
      this.date = null;
      this.stepPosition = 1;
      this.isEditing = false;
      this.fields.isEditing = false;
      this.fields.timeSlot = null;
      this.timeSlots = [];
      this.dialog = false;
      this.loading = false;
      this.errors = {};
      this.loaders.complete = false;
    },

    allowedDates(date) {
      return !this.datePicker.disabledDays.includes(date);
    },
    monthChanged(date) {
      this.datePicker.overlay = true;

      this.$store
        .dispatch("arnprior/podsStore/getDisabledDaysParty", {
          appId: this.$route.params.id,
          fields: {
            date: date.concat("-01"),
          },
        })
        .then((resp) => {
          this.datePicker.disabledDays = resp.data.disabledDays;
          this.datePicker.overlay = false;
        })
        .catch(() => {
          this.datePicker.overlay = false;
        });
    },

    loadSummaryStep() {
      this.loaders.summary = true;

      const fields = _.mapKeys(this.fields, (field, key) => {
        return _.snakeCase(key);
      });

      this.$store
        .dispatch("arnprior/podsStore/getPartySummary", {
          appId: this.$route.params.id,
          fields,
        })
        .then((resp) => {
          this.fields.subTotal = resp.totals.subTotal;
          this.fields.vatTotal = resp.totals.vatTotal;
          this.fields.total = resp.totals.total;

          this.loaders.summary = false;
          this.stepPosition = 3;
        })
        .catch(() => (this.loaders.summary = false));
    },

    createBooking() {
      this.loaders.complete = true;

      const fields = _.mapKeys(this.fields, (field, key) => {
        return _.snakeCase(key);
      });

      fields.date = this.date;

      let payload = {
        appId: this.$route.params.id,
        fields,
      };

      if (this.booking) {
        payload.isEditing = true;
        payload.bookingId = this.booking.id;
      }

      this.$store
        .dispatch("arnprior/ordersStore/savePartyPodBooking", payload)
        .then((resp) => {
          if (this.order === null) {
            this.$router.push({
              name: "module-arnprior-orders-individual",
              params: {
                orderId: resp.data.order_id,
              },
            });
          } else {
            this.resetForm();
            this.$emit("close-dialog");
          }
        });
    },

    dateSelected(date) {
      this.loaders.timeSlots = true;
      this.datePicker.menu = false;

      this.fields.date = date;

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      this.$store
        .dispatch("arnprior/podsStore/getPartyTimeSlots", payload)
        .then((resp) => {
          this.timeSlots = resp.time_slots;
        })
        .finally(() => (this.loaders.timeSlots = false));
    },
  },
};
</script>
<style scoped>
.hide {
  display: none;
}
</style>
