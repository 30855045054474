<template>
  <div>
    <v-card class="mb-4">
      <v-toolbar flat dark dense color="accent">
        <v-toolbar-title>Glamping Pod Bookings</v-toolbar-title>
        <div class="spacer"></div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              class="mr-2"
              color="blue lighten-4 blue--text"
              :href="
                'mailto:' +
                order.customer.email +
                '?subject=Your Glamping Booking with Arnprior&body=You have the Glamping Pod booked for:%0D%0A' +
                mailText
              "
            >
              <v-icon small>mdi-email</v-icon>
            </v-btn>
          </template>
          <span>Send Email</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="green lighten-4 green--text"
              v-on="on"
              @click="openForm()"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Pod Booking</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
      <v-simple-table>
        <thead>
          <tr>
            <th>Pod</th>
            <th>Check In</th>
            <th>Check Out</th>
            <th>Total Guests</th>
            <th class="text-right">Total Cost</th>
            <th class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="booking in bookings" :key="`${booking.id}-row`">
            <td v-html="booking.booking_is.pod.name" />
            <td
              v-html="
                booking.formatted_dates.start_date +
                ' at ' +
                booking.formatted_dates.start_time
              "
            />
            <td
              v-html="
                booking.formatted_dates.end_date +
                ' at ' +
                booking.formatted_dates.end_time
              "
            />
            <td v-html="booking.booking_is.total_guests" />
            <td
              class="text-right"
              v-html="'£' + booking.booking_is.total_cost"
            />
            <td class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    class="mr-2"
                    v-on="on"
                    @click="openForm(booking)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Individual Booking</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="openDelete(booking)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Remove Glamping Pod Booking</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600"
      @click:outside="resetForm()"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ isEditing ? "Edit" : "Add a new" }} Pod Booking</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form @submit.prevent="saveForm" method="post" id="pod-form">
            <v-select
              label="Pod *"
              :items="pods"
              item-value="id"
              item-text="name"
              v-model="fields.pod_id"
              outlined
              background-color="white"
              :error="errors.hasOwnProperty('pod_id')"
              :error-messages="errors['pod_id']"
            ></v-select>
            <v-text-field
              label="Check In"
              type="date"
              v-model="fields.check_in"
              outlined
              background-color="white"
              :error="errors.hasOwnProperty('check_in')"
              :error-messages="errors['check_in']"
            ></v-text-field>
            <v-text-field
              label="Check Out"
              type="date"
              v-model="fields.check_out"
              outlined
              background-color="white"
              :error="errors.hasOwnProperty('check_out')"
              :error-messages="errors['check_out']"
            ></v-text-field>
            <v-text-field
              label="Total Adults *"
              v-model="fields.total_adults"
              type="number"
              min="0"
              outlined
              background-color="white"
              :error="errors.hasOwnProperty('total_adults')"
              :error-messages="errors['total_adults']"
            ></v-text-field>
            <v-text-field
              label="Total Kids *"
              v-model="fields.total_kids"
              type="number"
              min="0"
              outlined
              background-color="white"
              :error="errors.hasOwnProperty('total_kids')"
              :error-messages="errors['total_kids']"
            ></v-text-field>
            <v-text-field
              label="Total Infants *"
              v-model="fields.total_infants"
              type="number"
              min="0"
              outlined
              background-color="white"
              :error="errors.hasOwnProperty('total_infants')"
              :error-messages="errors['total_infants']"
            ></v-text-field>
            <v-text-field
              label="Total Babies *"
              v-model="fields.total_babies"
              type="number"
              min="0"
              outlined
              background-color="white"
              :error="errors.hasOwnProperty('total_babies')"
              :error-messages="errors['total_babies']"
            ></v-text-field>
            <v-checkbox
              label="Would you like the Order Totals to be updated?"
              v-model="fields.update_price"
              v-if="isEditing"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="pod-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteBooking.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline"
          >Remove Glamping Pod Booking</v-card-title
        >
        <v-card-text
          >Are you sure you want to remove this Glamping Pod Booking? If no
          other items are attached to this order, the entire order will be
          removed as well.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteBooking.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    bookings: Array,
    order: Object,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      booking: {},
      fields: {
        pod_id: null,
        check_in: null,
        check_out: null,
        total_adults: 0,
        total_kids: 0,
        total_infants: 0,
        total_babies: 0,
        update_price: true,
      },
      errors: {},
      deleteBooking: {
        dialog: false,
        booking: {},
        loading: false,
      },
    };
  },

  computed: {
    mailText() {
      var total = "";

      this.order.pod_bookings.forEach((value) => {
        total +=
          value.formatted_dates.start_date +
          " on " +
          value.formatted_dates.end_date +
          " in " +
          value.booking_is.pod.name +
          " %0D%0A";
      });

      total +=
        "We look forward to welcoming you to Arnprior Pods! Remember if you didn't manage to get a pool slot booked, you can use the pool between 7-9am each morning.%0D%0A%0D%0AJust a few notes for your arrival.%0D%0AOur Address - Arnprior Farm, Arnprior, Stirlingshire, FK8 3HA%0D%0ACheck in at 3pm%0D%0ACheck out at 10am%0D%0AParking- Please park at the Swimming pool and Glamping carpark. DO NOT take your cars up to the pods.%0D%0AAccess Code - 1111%0D%0ALock Box - is located on the pod main door, use the access code to open it, slide the black grooved button down to reveal the key%0D%0AKey Return - place the key back in the lock box on checkout%0D%0AWe provide%0D%0ABed Linen%0D%0ACrockery%0D%0ACutlery%0D%0AToaster/Kettle/Microwave%0D%0ATV %26 DVD player%0D%0AWe do not provide Towels and Robes, but you can hire these from our vending hut on site.%0D%0AIf you've got a query, please do email me at hello@arnpriorfarm.com%0D%0ASee you soon!%0D%0ARebecca";

      return total;
    },

    pods() {
      return this.$store.getters["arnprior/podsStore/all"];
    },
  },

  methods: {
    openForm(booking = null) {
      if (booking !== null) {
        this.booking = booking;
        this.isEditing = true;
        this.fields.pod_id = booking.booking_is.glamping_pod_id;
        this.fields.check_in = booking.start_date.substr(0, 10);
        this.fields.check_out = booking.end_date.substr(0, 10);
        this.fields.total_adults = booking.booking_is.total_adults;
        this.fields.total_kids = booking.booking_is.total_kids;
        this.fields.total_infants = booking.booking_is.total_infants;
        this.fields.total_babies = booking.booking_is.total_babies;
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.errors = {};
      this.booking = {};
      this.isEditing = false;
      this.fields.pod_id = null;
      this.fields.check_in = null;
      this.fields.check_out = null;
      this.fields.total_adults = 0;
      this.fields.total_kids = 0;
      this.fields.total_infants = 0;
      this.fields.total_babies = 0;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        orderId: this.$route.params.orderId,
        fields: this.fields,
        isEditing: this.isEditing,
      };

      if (this.isEditing) {
        payload.bookingId = this.booking.id;
      }

      this.$store
        .dispatch("arnprior/ordersStore/savePodBooking", payload)
        .then(() => this.resetForm())
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    openDelete(booking) {
      this.deleteBooking.booking = booking;
      this.deleteBooking.dialog = true;
    },

    resetDelete() {
      this.deleteBooking.dialog = false;
      this.deleteBooking.booking = {};
      this.deleteBooking.loading = false;
    },

    saveDelete() {
      this.deleteBooking.loading = true;

      this.$store
        .dispatch("arnprior/ordersStore/deleteBooking", {
          appId: this.$route.params.id,
          bookingId: this.deleteBooking.booking.id,
          orderId: this.$route.params.orderId,
        })
        .then((resp) => {
          if (resp.data.orderDeleted) {
            this.$store.commit("showGlobalSnackbar", {
              open: true,
              text: "Order has been Removed",
              color: "orange",
            });

            this.$router.push({
              name: "module-arnprior-bookings",
              params: {
                appId: this.$route.params.id,
              },
            });
          }

          this.resetDelete();
        })
        .catch(() => {
          this.deleteBooking.loading = false;
        });
    },
  },
};
</script>
