<template>
  <v-card class="mb-4">
    <v-toolbar flat dark dense color="accent">
      <v-toolbar-title>Payments Received</v-toolbar-title>
      <div class="spacer"></div>
      <v-dialog
        v-model="dialog"
        scrollable
        width="500"
        :disabled="outstanding_total === '0.00'"
      >
        <template v-slot:activator="{ on: dialogOn, attrs: dialogAttrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltipOn }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                :disabled="outstanding_total === '0.00'"
                v-bind="dialogAttrs"
                v-on="{ ...tooltipOn, ...dialogOn }"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add Payment</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-card-title class="headline">Add Payment</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
            <v-form @submit.prevent="save" method="post" id="payment-form">
              <v-text-field
                label="Amount *"
                v-model="fields.amount"
                type="number"
                min="0.00"
                :max="outstanding_total"
                step="1.00"
                prefix="£"
                outlined
                background-color="white"
              ></v-text-field>
              <v-select
                label="Payment Method *"
                v-model="fields.payment_method"
                :items="payment_methods"
                outlined
                background-color="white"
              ></v-select>
              <v-text-field
                label="Stripe Payment Intent"
                v-model="fields.payment_intent"
                v-if="fields.payment_method === 'stripe'"
                outlined
                background-color="white"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="reset">Close</v-btn>
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="loading"
              form="payment-form"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-simple-table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Type</th>
          <th>Payment Method</th>
          <th class="text-right">Paid</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="payment in payments" :key="payment.id">
          <td v-html="formatDateTime(payment.created_at)" />
          <td v-html="ucfirst(payment.type)" />
          <td v-html="ucfirst(payment.payment_method)" />
          <td v-html="'&#163;' + payment.amount" class="text-right" />
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  props: {
    payments: Array,
    outstanding_total: String,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      errors: {},
      fields: {
        type: null,
        payment_method: null,
        amount: null,
        payment_intent: null,
      },
      types: [
        {
          value: "remainder",
          text: "Remaining Amount",
        },
        {
          value: "partial",
          text: "Partial Amount",
        },
        {
          value: "full",
          text: "Full Amount",
        },
      ],
      payment_methods: [
        {
          value: "stripe",
          text: "Stripe",
        },
        {
          value: "bank-transfer",
          text: "Bank Transfer",
        },
        {
          value: "cash",
          text: "Cash",
        },
      ],
    };
  },

  methods: {
    save() {
      this.loading = true;
      this.errors = {};

      if (
        parseFloat(this.fields.amount) == parseFloat(this.outstanding_total)
      ) {
        this.fields.type = "remainder";
      } else {
        this.fields.type = "partial";
      }

      this.$store
        .dispatch("arnprior/ordersStore/savePayment", {
          appId: this.$route.params.id,
          orderId: this.$route.params.orderId,
          fields: this.fields,
        })
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.errors = {};
      this.fields = {
        type: null,
        payment_method: null,
        amount: null,
        payment_intent: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
