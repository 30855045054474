<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-arnprior-bookings' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Bookings</v-btn
        >
      </v-row>
      <v-row no-gutters align="center">
        <v-col class="pr-12">
          <h1>Individual Order - {{ order.customer.full_name }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn depressed color="red lighten-4 red--text" @click="openDelete">
            <v-icon left>mdi-cancel</v-icon> Cancel Full Order
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Order Totals</v-toolbar-title>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Discount Total</th>
                  <td class="text-right" v-html="'£' + order.discount_total" />
                </tr>
                <!-- <tr>
                  <th>Sub Total</th>
                  <td class="text-right" v-html="'£' + order.sub_total" />
                </tr>
               <tr>
                  <th>VAT Total</th>
                  <td class="text-right" v-html="'£' + order.vat_total" />
                </tr>-->
                <tr class="green lighten-5">
                  <th>Total</th>
                  <td class="text-right" v-html="'£' + order.total" />
                </tr>
                <tr>
                  <th>Total Paid</th>
                  <td class="text-right" v-html="'£' + order.payments_total" />
                </tr>
                <tr>
                  <th>Total Outstanding</th>
                  <td class="text-right">
                    <v-btn
                      v-if="order.outstanding_total > 0"
                      color="success"
                      class="mr-2"
                      x-small
                      depressed
                      @click="openChaseBalance()"
                      >Chase balance</v-btn
                    >
                    £{{ order.outstanding_total }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Basic Details</v-toolbar-title>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Full Name</th>
                  <td>
                    <router-link
                      :to="{
                        name: 'module-arnprior-customers-individual',
                        params: { customerId: order.customer.id },
                      }"
                      >{{ order.customer.full_name }}</router-link
                    >
                  </td>
                </tr>
                <tr v-if="order.billing_address">
                  <th>Billing Address</th>
                  <td>
                    <div>{{ order.billing_address.full_name }}</div>
                    <div>{{ order.billing_address.line_1 }}</div>
                    <div v-if="order.billing_address.line_2">
                      {{ order.billing_address.line_2 }}
                    </div>
                    <div v-if="order.billing_address.line_3">
                      {{ order.billing_address.line_3 }}
                    </div>
                    <div>{{ order.billing_address.city }}</div>
                    <div v-if="order.billing_address.county">
                      {{ order.billing_address.county }}
                    </div>
                    <div>{{ order.billing_address.postcode }}</div>
                    <div v-if="order.billing_address.country">
                      {{ order.billing_address.country }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ order.customer.email }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <payments
            :payments="order.payments"
            :outstanding_total="order.outstanding_total"
          />
        </v-col>
        <v-col cols="8">
          <glamping-pods
            :order="order"
            :bookings="order.pod_bookings"
            v-if="order.pod_bookings.length > 0"
          />
          <swim :order="order" :bookings="order.swim_bookings" />

          <pool-party
            :order="order"
            :bookings="order.pool_party_bookings"
            v-if="order.pool_party_bookings.length > 0"
          />

          <extras
            :extras="order.pod_booking_extras"
            v-if="order.pod_bookings.length > 0"
          />
          <party-pod
            :bookings="order.party_pod_bookings"
            :order="order"
            v-if="order.party_pod_bookings.length > 0"
          />

          <v-card>
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Audit Timeline</v-toolbar-title>
            </v-toolbar>
            <v-timeline
              dense
              class="pr-5"
              v-if="order.audits && order.audits.length"
            >
              <v-timeline-item
                v-for="audit in order.audits"
                v-bind:key="audit.id"
                :icon="audit.action.icon"
                :color="audit.action.colour"
                fill-dot
              >
                <v-card>
                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="3">
                        <div class="caption">
                          {{ audit.happened_at_formatted }}
                        </div>
                        <div class="caption">
                          {{
                            audit.happened_by !== null
                              ? audit.happened_by.full_name
                              : "SYSTEM"
                          }}
                        </div>
                      </v-col>
                      <v-col>
                        <div v-if="audit.body">
                          {{ audit.body }}
                        </div>
                        <div v-else>
                          {{ audit.action.title }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
            <v-card-text v-else
              >There has been no activity detected</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deleteOrder.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline"
          >Cancel
          {{ pluralString(order.customer.first_name) }} Order</v-card-title
        >
        <v-card-text>Are you sure you want to cancel this Order?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteOrder.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="chaseBalance.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline"> Chase Balance</v-card-title>
        <v-card-text
          >Are you sure you want to chase this balance due?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetChaseBalance">No</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="chaseBalance.loading"
            @click="sendEmailBalance"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Extras from "./components/Extras";
import GlampingPods from "./components/GlampingPods";
import PartyPod from "./components/PartyPod";
import PoolParty from "./components/PoolParty";
import Swim from "./components/Swim";
import Payments from "./components/Payments";

export default {
  components: {
    Extras,
    PoolParty,
    GlampingPods,
    PartyPod,
    Swim,
    Payments,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Orders",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Date", value: "order.customer.full_name" },
        { text: "Booking Time", value: "formatted_dates.start_date" },
        { text: "# of Swimmers", value: "total_attendees" },
        { text: "Cost", value: "order.total" },
        { text: "Type", value: "booking_type" },
        { text: "Adults", value: "total_adults" },
        { text: "Kids", value: "total_kids" },
        { text: "Infants", value: "total_infants" },
        { text: "Babies", value: "total_babies" },
      ],
      swimTableHeaders: [
        { text: "Date", value: "order.customer.full_name" },
        { text: "Booking Time", value: "formatted_dates.start_date" },
        { text: "# of Swimmers", value: "total_attendees" },
        { text: "Cost", value: "order.total" },
      ],
      deleteOrder: {
        dialog: false,
        loading: false,
      },
      chaseBalance: {
        dialog: false,
        loading: false,
      },
    };
  },

  computed: {
    order() {
      return this.$store.getters["arnprior/ordersStore/get"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: "Order #" + this.order.id,
      disabled: true,
    });

    this.breadcrumbs.push({
      text: this.order.customer.full_name,
      disabled: true,
    });
  },

  methods: {
    openDelete() {
      this.deleteOrder.dialog = true;
    },

    openChaseBalance() {
      this.chaseBalance.dialog = true;
    },

    resetChaseBalance() {
      this.chaseBalance.dialog = false;
      this.chaseBalance.loading = false;
    },

    sendEmailBalance() {
      this.chaseBalance.loading = true;
      this.$store
        .dispatch("arnprior/ordersStore/sendEmailBalance", {
          appId: this.$route.params.id,
          orderId: this.$route.params.orderId,
        })
        .then(() => {
          this.resetChaseBalance();
        })
        .catch(() => {
          this.chaseBalance.loading = false;
        });
    },

    resetDelete() {
      this.deleteOrder.dialog = false;
      this.deleteOrder.loading = false;
    },

    saveDelete() {
      this.deleteOrder.loading = true;

      this.$store
        .dispatch("arnprior/ordersStore/deleteOrder", {
          appId: this.$route.params.id,
          orderId: this.$route.params.orderId,
        })
        .then(() => {
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Order has been Cancelled",
            color: "orange",
          });

          this.$router.push({
            name: "module-arnprior-bookings",
            params: {
              appId: this.$route.params.id,
            },
          });

          this.resetDelete();
        })
        .catch(() => {
          this.deleteBooking.loading = false;
        });
    },
  },
};
</script>
